<template>
  <div class="content">
    <div class="row">
      <div class="col-12">
        <card>
          <div class="ml-1">
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">Home</li>
                <li class="breadcrumb-item active" aria-current="page">
                  {{ $t("liq_reporting.title") }}
                </li>
              </ol>
            </nav>
          </div>
          <div class="row ml-2 mb-3">
            <b-col cols="6">
              <h6 class="ssp-24">{{ $t("liq_reporting.title") }}</h6>
            </b-col>
            <b-col cols="6" class="text-right">
              <button class="btn btn-success btn-add mr-4" @click="onAdd">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="20"
                  height="20"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                    fill="rgba(255,255,255,1)"
                  />
                </svg>
                {{ $t("liq_reporting.add") }}
              </button>
            </b-col>
          </div>
          <div class="row table-utilities">
            <div class="row ml-2 w-100">
              <div class="col-md-10 search-section">
                <div class="input-group">
                  <div class="input-group-prepend bg-white">
                    <span class="input-group-text" id="basic-addon1">
                      <div class="font-12">
                        <i class="ri-search-line ri-lg ri-sub"></i>
                      </div>
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control search-input"
                    :placeholder="$t('liquidation.search')"
                    @input="setPage"
                    v-model="filterInput"
                  />
                </div>
              </div>
              <div class="col-md-2 pl-0">
                <button
                  class="btn table-button ssp-14-700 export-button h-100 w-100 d-flex align-items-center"
                  @click="handleExport"
                  :disabled="isLoading"
                >
                  <div class="font-12">
                    <div
                      v-if="isLoading"
                      class="spinner-border spinner-border-sm text-success mr-1"
                    >
                      <span class="sr-only">Loading...</span>
                    </div>
                    <i v-else class="ri-download-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.export") }}
                </button>
              </div>
            </div>
            <div class="row w-100 ml-2 mt-2">
              <div class="col-md-2 pl-0">
                <b-button
                  class="btn table-button ssp-14-700 h-100 d-flex align-items-center"
                  id="popover-button-variant"
                  href="#"
                  tabindex="0"
                >
                  <div class="font-12">
                    <i class="ri-filter-2-line ri-lg ri-mid mr-1"></i>
                  </div>
                  {{ $t("global.filter") }}
                </b-button>
              </div>
              <b-popover
                placement="right"
                target="popover-button-variant"
                variant="danger"
                triggers="focus"
              >
                <b-tabs content-class="mt-2">
                  <b-tab title="Tanggal" active>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-today"
                        :value="filterDateTypes[0]"
                        @change="resetFilter1"
                        checked
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-today">
                        <div class="col">
                          <div class="row ssp-14">Today</div>
                          <div class="row">{{ today | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-yesterday"
                        :value="filterDateTypes[1]"
                        @change="resetFilter1"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-yesterday">
                        <div class="col">
                          <div class="row ssp-14">Yesterday</div>
                          <div class="row">{{ yesterday | formatDate2 }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-week"
                        :value="filterDateTypes[2]"
                        @change="setFilterSelectedWeek"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-week">
                        <div class="col">
                          <div class="row ssp-14">This week</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayWeek)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayWeek | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check multiple-row">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-month"
                        :value="filterDateTypes[3]"
                        @change="setFilterSelectedMonth"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label" for="radio-month">
                        <div class="col">
                          <div class="row ssp-14">This month</div>
                          <div class="row">
                            <span>{{
                              `${getDay(firstDayMonth)}&nbsp;-&nbsp;`
                            }}</span>
                            <span>{{ lastDayMonth | formatDate2 }}</span>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="form-check mb-2">
                      <input
                        class="form-check-input"
                        type="radio"
                        name="exampleRadios"
                        id="radio-custom"
                        :value="filterDateTypes[4]"
                        v-model="filterDateType"
                      />
                      <label class="form-check-label ssp-14" for="radio-custom">
                        Custom Range
                      </label>
                    </div>
                    <b-row
                      v-if="filterDateType == filterDateTypes[4]"
                      class="mb-2"
                    >
                      <b-col cols="6" class="pr-1">
                        <span>FROM</span>
                        <input
                          type="date"
                          class="form-control float-right"
                          v-model="filterSelected1"
                        />
                      </b-col>
                      <b-col cols="6" class="pl-1">
                        <span>TO</span>
                        <input
                          type="date"
                          class="form-control"
                          v-model="filterSelected2"
                        />
                      </b-col>
                    </b-row>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 ssp-14"
                      :class="{ disabled: filterDateType === null }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Produk">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchProduk"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="prod in dataProduk"
                        :key="prod.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :id="prod.id"
                          :value="prod.id"
                          v-model="filterProduk"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="prod.id"
                        >
                          {{ prod.brand }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterProduk == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="R2">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchR2"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="r2 in dataR2"
                        :key="r2.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :id="r2.id"
                          :value="parseInt(r2.id)"
                          v-model="filterR2"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="r2.id"
                        >
                          {{ `${r2.name} (${r2.owner ? r2.owner : "-"})` }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterR2 == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="R1">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchR1"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="r1 in dataR1"
                        :key="r1.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :id="r1.id"
                          :value="parseInt(r1.id)"
                          v-model="filterR1"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="r1.id"
                        >
                          {{ `${r1.name} (${r1.owner ? r1.owner : "-"})` }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterR1 == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                  <b-tab title="Distri">
                    <div class="input-group">
                      <div class="input-group-prepend bg-white">
                        <span class="input-group-text" id="basic-addon1">
                          <div class="font-12">
                            <i class="ri-search-line ri-lg ri-sub"></i>
                          </div>
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control search-input"
                        v-model="searchDist"
                      />
                    </div>
                    <hr class="m-2" />
                    <div class="filter-overflow-srch">
                      <div
                        class="form-checkbox"
                        v-for="dist in dataDistributor"
                        :key="dist.id"
                      >
                        <input
                          class="form-checkbox-item"
                          type="checkbox"
                          name="kegiatan-label"
                          :id="dist.id"
                          :value="parseInt(dist.id)"
                          v-model="filterDist"
                        />
                        <label
                          class="form-checkbox-label ssp-14-400"
                          :for="dist.id"
                        >
                          {{ `${dist.name} (${dist.owner})` }}
                        </label>
                      </div>
                    </div>
                    <button
                      @click="resetFilter"
                      type="string"
                      class="btn btn-success w-100 mt-2 ssp-14"
                      :class="{ disabled: filterDist == '' }"
                    >
                      {{ $t("filter.reset") }}
                    </button>
                  </b-tab>
                </b-tabs>
              </b-popover>
            </div>
          </div>
          <div class="table-borderless mx-3">
            <b-table-simple hover responsive>
              <b-thead class="bv-head">
                <b-tr>
                  <b-th
                    ><input
                      type="checkbox"
                      @click="selectAll"
                      v-model="allSelected"
                  /></b-th>
                  <b-th v-for="hd in $t('liq_reporting.headers')" :key="hd.id">
                    {{ hd }}
                  </b-th>
                </b-tr>
              </b-thead>
              <b-tbody v-if="loading">
                <table-spinner cs="10" />
              </b-tbody>
              <!-- <b-tbody> -->
              <b-tbody v-else-if="dataLaporan.length !== 0">
                <b-tr
                  class="ssp-16-400"
                  v-for="laporan in dataLaporan"
                  :key="laporan.id"
                >
                  <b-td
                    ><input
                      type="checkbox"
                      :value="laporan.id"
                      v-model="lapIDs"
                      @click="select"
                  /></b-td>
                  <b-td>{{ laporan.day_report | formatDate2 }}</b-td>
                  <b-td>{{ laporan.produknames.brand }}</b-td>
                  <b-td>{{ laporan.price | formatRp }}</b-td>
                  <b-td>{{ laporan.quantity }}</b-td>
                  <b-td>{{ laporan.value | formatRp }}</b-td>
                  <b-td>
                    <span v-if="laporan.retailers2 != null">{{
                      laporan.retailers2.name
                    }}</span>
                    <span v-else> - </span>
                  </b-td>
                  <b-td>
                    <span v-if="laporan.retailers1 != null">{{
                      laporan.retailers1.name
                    }}</span>
                    <span v-else> - </span>
                  </b-td>
                  <b-td>
                    <span v-if="laporan.distributors != null">{{
                      laporan.distributors.name
                    }}</span>
                    <span v-else> - </span>
                  </b-td>
                  <!-- <b-td>
                    <button
                      :id="`popover-1-${laporan.id}`"
                      class="btn btn-invc p-0"
                    >
                      <div class="action-wrapper font-12">
                        <i class="ri-more-2-fill ri-lg ri-mid"></i>
                      </div>
                    </button>
                    <b-popover
                      :target="`popover-1-${laporan.id}`"
                      placement="right"
                      triggers="focus"
                    >
                      <div class="action-wrapper font-12">
                        <table>
                          <tr class="pointer" @click="onDetail(laporan)">
                            <td style="padding: 4px">
                              <i
                                class="ri-eye-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.detail") }}</td>
                          </tr>
                          <tr class="pointer" @click="onEdit(laporan)">
                            <td style="padding: 4px">
                              <i
                                class="ri-edit-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.edit") }}</td>
                          </tr>
                          <tr class="pointer" @click="onDelete(laporan.id)">
                            <td style="padding: 4px">
                              <i
                                class="ri-delete-back-2-fill ri-lg ri-sub action-btn"
                              ></i>
                            </td>
                            <td>{{ $t("global.delete") }}</td>
                          </tr>
                        </table>
                      </div>
                    </b-popover>
                  </b-td> -->
                  <b-td class="action-column">
                    <div class="action-wrapper font-12">
                      <i
                        class="ri-eye-fill ri-lg ri-mid action-btn"
                        @click="onDetail(laporan)"
                      ></i>
                    </div>
                  </b-td>
                </b-tr>
              </b-tbody>
              <b-tbody v-else class="text-center text-italic">
                <!-- <b-tbody class="text-center"> -->
                <b-tr>
                  <b-td colspan="10">{{ $t("global.empty") }}</b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <div class="row align-items-center table-footer">
              <div class="col-sm-6 ssp-14-700">
                <!-- 1 - 6 of {{ this.totalData }} -->
                {{ $t("global.total_data") }} {{ this.totalData }}
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="col-sm-7 align-self-center page-text">
                    {{ $t("global.page_on") }}
                  </div>
                  <div class="col float-right">
                    <div class="row">
                      <select
                        class="form-control pagination-pg"
                        v-model="currPage"
                      >
                        <option
                          v-for="index in totalPages"
                          :key="index.id"
                          :value="index"
                        >
                          {{ index++ }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col page-arrow ssp-14-700 ml-2">
                    <div class="row float-right">
                      <div
                        @click="redcPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div class="font-12" :class="{ orange: currPage > 1 }">
                          <i class="ri-arrow-left-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                      <div
                        @click="addPage"
                        class="btn table-button pagination-btn ml-auto d-flex align-items-center"
                      >
                        <div
                          class="font-12"
                          :class="{ orange: currPage < totalPages }"
                        >
                          <i class="ri-arrow-right-line ri-lg ri-sub"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </card>
      </div>
    </div>
    <b-modal id="modal-laporan" hide-footer>
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <h5 class="ssp-24 text-black">
          <img
            class="mr-2"
            src="@/assets/img/tbhdata-form.png"
            alt="FORM"
            style="height: 1.8rem;"
          />
          {{ modalTitle }}
        </h5>
        <div class="pointer font-14" @click="close()">
          <i class="ri-close-circle-line ri-xl ri-mid"></i>
        </div>
      </template>

      <template #default="{ hide }">
        <form @submit.prevent="onSubmit">
          <section v-if="isDetail">
            <div class="form-group">
              <label for="day-report" class="ssp-16 text-black">
                {{ $t("liquidation.label.date") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="day-report"
                v-model="formDetail.day_report"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="nama-produk" class="ssp-16 text-black">
                {{ $t("liquidation.label.product") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="nama-produk"
                v-model="formDetail.produknames.brand"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="rp-produk" class="ssp-16 text-black">
                {{ $t("liquidation.label.rp") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="rp-produk"
                v-model="formDetail.price"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="qty-produk" class="ssp-16 text-black">
                {{ $t("liquidation.label.qty") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="qty-produk"
                v-model="formDetail.quantity"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="value-produk" class="ssp-16 text-black">
                {{ $t("liquidation.label.value") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="value-produk"
                v-model="formDetail.value"
                disabled
              />
            </div>
            <div class="form-group" v-if="formDetail.retailers1">
              <label for="r1-liq" class="ssp-16 text-black">
                {{ $t("liquidation.label.r1") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="r1-liq"
                v-model="formDetail.retailers1.name"
                disabled
              />
            </div>
            <div class="form-group" v-if="formDetail.retailers2">
              <label for="r2-liq" class="ssp-16 text-black">
                {{ $t("liquidation.label.r2") }}
              </label>
              <input
                type="text"
                class="form-control"
                id="r2-liq"
                v-model="formDetail.retailers2.name"
                disabled
              />
            </div>
            <div class="form-group" v-if="formDetail.distributors">
              <label for="dist-liq" class="ssp-16 text-black">
                Distributor
              </label>
              <input
                type="text"
                class="form-control"
                id="dist-liq"
                v-model="formDetail.distributors.name"
                disabled
              />
            </div>
            <div class="form-group">
              <label for="invoice-liq" class="ssp-16 text-black">
                {{ $t("liquidation.label.inv") }}
              </label>
              <img
                id="invoice-liq"
                class="d-block ml-auto mr-auto"
                v-if="formDetail.invoice !== null"
                :src="url + formDetail.invoice"
                :alt="formDetail.invoice"
              />
              <div v-else class="mx-auto text-center">
                {{ $t("feedback.not_avb") }}
              </div>
            </div>
          </section>
          <section v-else>
            <section v-for="(form, index) in forms" :key="form.id">
              <div class="form-group">
                <section>
                  <label for="nama-produk" class="ssp-16 text-black">
                    {{ $t("liquidation.label.product") + " " + (index + 1) }}
                    <span v-if="!isDetail" class="text-red">*</span>
                  </label>
                  <span
                    v-if="index > 0"
                    class="float-right text-red pointer"
                    @click="removeReport(index)"
                  >
                    {{ $t("global.delete") }}
                  </span>
                </section>
                <custom-select
                  :placeholder="$t('liq_reporting.placeholder.product')"
                  :class="{
                    'invalid-form rounded-input':
                      $v.forms.$each[index].produk_id.$error
                  }"
                  v-model="form.produk_id"
                  :options="dataProduk.map(produk => produk.id)"
                  :custom-label="opt => dataProduk.find(x => x.id == opt).brand"
                >
                </custom-select>
                <div
                  class="text-sm text-red"
                  v-if="$v.forms.$each[index].produk_id.$error"
                >
                  <div>{{ $t("feedback.required") }}</div>
                </div>
              </div>
              <div class="form-group">
                <label for="harga-retailer" class="ssp-16 text-black"
                  >{{ $t("liquidation.label.rp") }}
                  <span v-if="!isDetail" class="text-red">*</span>
                </label>
                <vue-numeric
                  currency="Rp"
                  separator="."
                  class="form-control"
                  :class="{
                    'invalid-form': $v.forms.$each[index].price.$error
                  }"
                  v-model="form.price"
                  :placeholder="$t('liq_reporting.placeholder.rp')"
                  @input="setLiquidationValue(index)"
                />
                <div
                  class="text-sm text-red"
                  v-if="$v.forms.$each[index].price.$error"
                >
                  <div v-if="!$v.forms.$each[index].price.required">
                    {{ $t("feedback.required") }}
                  </div>
                  <div v-if="!$v.forms.$each[index].price.numeric">
                    {{ $t("feedback.invalid") }}
                  </div>
                  <div v-if="!$v.forms.$each[index].price.maxLength">
                    {{ $t("feedback.exceeds") }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="qty-lkd" class="ssp-16 text-black"
                  >{{ $t("liquidation.label.qty") }}
                  <span v-if="!isDetail" class="text-red">*</span>
                </label>
                <input
                  type="text"
                  class="form-control"
                  :class="{
                    'invalid-form': $v.forms.$each[index].quantity.$error
                  }"
                  id="qty-lkd"
                  v-model="form.quantity"
                  :placeholder="$t('liq_reporting.placeholder.qty')"
                  @input="setLiquidationValue(index)"
                />
                <div
                  class="text-sm text-red"
                  v-if="$v.forms.$each[index].quantity.$error"
                >
                  <div v-if="!$v.forms.$each[index].quantity.required">
                    {{ $t("feedback.required") }}
                  </div>
                  <div v-if="!$v.forms.$each[index].quantity.numeric">
                    {{ $t("feedback.invalid") }}
                  </div>
                  <div v-if="!$v.forms.$each[index].quantity.maxLength">
                    {{ $t("feedback.exceeds") }}
                  </div>
                </div>
              </div>
              <div class="form-group">
                <label for="val-lqd" class="ssp-16 text-black">
                  {{ $t("liquidation.label.value") }}
                </label>
                <vue-numeric
                  id="val-lqd"
                  currency="Rp"
                  separator="."
                  class="form-control"
                  v-model="form.value"
                  placeholder="Harga Retailer x Quantity Likuidasi"
                  disabled
                />
              </div>
              <div class="form-group" v-if="!isDetail">
                <label for="retailer" class="ssp-16 text-black">
                  {{ $t("retailers.label.type") }}
                  <span class="text-red">*</span>
                </label>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="`retailer${index}`"
                    :id="`retailer1-${index}`"
                    value="r1"
                    v-model="form.rs"
                    required
                  />
                  <label class="form-check-label" :for="`retailer1-${index}`">
                    Retailer 1
                  </label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    :name="`retailer${index}`"
                    :id="`retailer2-${index}`"
                    value="r2"
                    v-model="form.rs"
                    required
                  />
                  <label class="form-check-label" :for="`retailer2-${index}`">
                    Retailer 2
                  </label>
                </div>
              </div>
              <div
                class="form-group"
                v-if="
                  form.rs === 'r2' || (isDetail === true && form.r2 !== null)
                "
              >
                <label for="retailer-2" class="ssp-16 text-black">
                  Retailer 2
                  <span v-if="!isDetail" class="text-red">*</span>
                </label>
                <input
                  v-if="isDetail"
                  type="text"
                  class="form-control"
                  v-model="form.r2"
                  :disabled="true"
                />
                <custom-select
                  v-else
                  :placeholder="$t('liq_reporting.placeholder.r2')"
                  :class="{
                    'invalid-form rounded-input':
                      $v.forms.$each[index].r2.$error
                  }"
                  v-model="form.r2"
                  :options="dataR2.map(r2 => r2.id)"
                  :custom-label="
                    opt =>
                      `${dataR2.find(x => x.id == opt).name} (${
                        dataR2.find(x => x.id == opt).owner
                      })`
                  "
                  :disabled="isDisabled"
                >
                </custom-select>
                <div
                  class="text-sm text-red"
                  v-if="$v.forms.$each[index].r2.$error"
                >
                  <div>{{ $t("feedback.required") }}</div>
                </div>
              </div>
              <div
                class="form-group"
                v-if="form.rs !== undefined || isDetail === true"
              >
                <label for="retailer-1" class="ssp-16 text-black"
                  >Retailer 1
                  <span v-if="!isDetail" class="text-red">*</span>
                </label>
                <input
                  v-if="isDetail"
                  type="text"
                  class="form-control"
                  v-model="form.r1"
                  :disabled="true"
                />
                <custom-select
                  v-else
                  :placeholder="$t('liq_reporting.placeholder.r1')"
                  :class="{
                    'invalid-form rounded-input':
                      $v.forms.$each[index].r1.$error
                  }"
                  v-model="form.r1"
                  :options="dataR1.map(r1 => r1.id)"
                  :custom-label="
                    opt =>
                      `${dataR1.find(x => x.id == opt).name} (${
                        dataR1.find(x => x.id == opt).owner
                      })`
                  "
                  :disabled="isDisabled"
                >
                </custom-select>
                <div
                  class="text-sm text-red"
                  v-if="$v.forms.$each[index].r1.$error"
                >
                  <div>{{ $t("feedback.required") }}</div>
                </div>
              </div>
              <div
                class="form-group"
                v-if="
                  form.rs === 'r1' ||
                    (isDetail === true && form.distributor !== null)
                "
              >
                <label for="distributor" class="ssp-16 text-black">
                  Distributor
                  <span v-if="!isDetail" class="text-red">*</span>
                </label>
                <input
                  v-if="isDetail"
                  type="text"
                  class="form-control"
                  v-model="form.distributor"
                  :disabled="true"
                />
                <custom-select
                  v-else
                  :placeholder="$t('liq_reporting.placeholder.dist')"
                  :class="{
                    'invalid-form rounded-input':
                      $v.forms.$each[index].distributor.$error
                  }"
                  v-model="form.distributor"
                  :options="dataDistributor.map(r1 => r1.id)"
                  :custom-label="
                    opt =>
                      `${dataDistributor.find(x => x.id == opt).name} 
                    (${dataDistributor.find(x => x.id == opt).owner})`
                  "
                  :disabled="isDisabled"
                >
                </custom-select>
                <div
                  class="text-sm text-red"
                  v-if="$v.forms.$each[index].distributor.$error"
                >
                  <div>{{ $t("feedback.required") }}</div>
                </div>
              </div>
              <div class="form-group">
                <label :for="`invoice-${index}`" class="ssp-16 text-black">
                  {{ $t("liquidation.label.inv") }}
                  <span v-if="!isDetail" class="text-red">*</span>
                  <span v-if="isEdit" class="small">
                    <br />{{ $t("liq_reporting.placeholder.inv_change") }}</span
                  >
                </label>
                <b-form-file
                  :id="`invoice-${index}`"
                  v-if="!isDetail"
                  v-model="form.invoice"
                  accept=".jpg, .png, .jpeg"
                  :class="{
                    'invalid-form rounded-input':
                      $v.forms.$each[index].invoice.$error
                  }"
                  :placeholder="$t('global.excel_pch')"
                  drop-placeholder="Drop file here..."
                  @input="handleInvoiceInput(index)"
                >
                </b-form-file>
                <div
                  class="text-sm text-red"
                  v-if="$v.forms.$each[index].invoice.$error"
                >
                  <div>{{ $t("feedback.required") }}</div>
                </div>
              </div>
              <hr />
            </section>
            <b-row>
              <b-col cols="4" class="pr-2">
                <a
                  class="btn btn-success ssp-14 w-100 my-3 py-2"
                  @click="addReportForm"
                >
                  {{ $t("products.add") }}
                </a>
              </b-col>
              <b-col cols="8" class="pl-2">
                <button
                  v-if="!isDetail"
                  type="submit"
                  class="btn btn-primary ssp-14 w-100 my-3 py-2"
                  :class="{ disabled: loading }"
                >
                  <div v-if="loading" class="spinner mr-2 d-inline">
                    <span
                      class="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </div>
                  <span class="d-inline">
                    {{ $t("global.submit_btn") }}
                  </span>
                </button>
              </b-col>
            </b-row>
          </section>
        </form>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";
import VueNumeric from "vue-numeric";
import { Card } from "@/components/index";
import Multiselect from "vue-multiselect";

import "../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css";
import JwtService from "@/core/services/jwt.service";
import {
  required,
  numeric,
  maxLength,
  requiredIf
} from "vuelidate/lib/validators";
import {
  Q_LIST_REPORT
  // M_ADD_REPORT,
  // M_DEL_REPORT,
  // M_EDIT_REPORT
} from "@/graphql/tss";
import {
  Q_LIST_RETAILER,
  Q_LIST_DISTRIBUTOR,
  Q_LIST_PRODUK
} from "@/graphql/queries";
import { RetailerType, filterDateType } from "@/graphql/enum.type.js";
import { createMultipleReport } from "@/graphql/mutations";
// import Axios from "axios";
// import * as XLSX from "xlsx";

const customDecimal = value => /^\d*\.?\d*$/.test(value);

export default {
  components: {
    Card,
    VueNumeric,
    "custom-select": Multiselect
  },
  data() {
    return {
      isLoading: false,
      loading: false,

      dataLaporan: [],
      detailUser: JwtService.getDetail(),
      url: JwtService.getURL(),
      countryID: [],
      regionID: [],
      provID: [],
      kabID: [],

      totalPages: null,
      currPage: 1,
      perPage: 10,
      totalData: null,
      filterInput: "",
      filterProduk: [],
      filterR1: [],
      filterR2: [],
      filterDist: [],
      searchProduk: "",
      searchR1: "",
      searchR2: "",
      searchDist: "",
      show_filter: false,
      customFilter: false,
      filterSelected1: "",
      filterSelected2: "",
      filterDateType: null,

      today: moment(new Date(new Date().setHours(0, 0, 0, 0))).format(
        "YYYY-MM-DD"
      ),
      yesterday: moment(
        new Date(new Date().setHours(0, 0, 0, 0) - 86400000)
      ).format("YYYY-MM-DD"),
      firstDayWeek: moment()
        .startOf("week")
        .toDate(),
      lastDayWeek: moment()
        .endOf("week")
        .toDate(),
      firstDayMonth: null,
      lastDayMonth: null,

      lapIDs: [],
      selected: [],
      allSelected: false,

      dataProduk: [],
      dataR1: [],
      dataR2: [],
      dataDistributor: [],

      retailerTypeOptions: [RetailerType.R1, RetailerType.R2],
      filterDateTypes: [
        filterDateType.TODAY,
        filterDateType.YESTERDAY,
        filterDateType.THIS_WEEK,
        filterDateType.THIS_MONTH,
        filterDateType.CUSTOM_DATE
      ],
      month: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember"
      ],
      modalTitle: "",
      isEdit: false,
      isDisabled: false,
      isDetail: false,
      forms: [{}]
    };
  },
  validations: {
    forms: {
      $each: {
        produk_id: { required },
        price: { required, numeric, maxLength: maxLength(10) },
        quantity: { required, customDecimal, maxLength: maxLength(10) },
        value: { required },
        r1: { required },
        r2: {},
        distributor: {},
        invoice: {
          required: requiredIf(function() {
            return !this.isEdit;
          })
        }
      }
    }
  },
  apollo: {
    listLaporan: {
      query: () => Q_LIST_REPORT,
      variables() {
        return {
          page: this.currPage,
          limit: this.perPage,
          keyword: this.filterInput,
          from: [JwtService.getID()],
          to: "",
          country_id: [],
          region_id: [],
          prov_id: [],
          kab_id: [],
          kec_id: [],
          kel_id: [],
          r1: this.filterR1,
          r2: this.filterR2,
          dis: this.filterDist,
          petani: [],
          produk: this.filterProduk,
          start: this.filterSelected1,
          end: this.filterSelected2,
          dateType: this.filterDateType
        };
      },
      debounce: 500,
      result({ data }) {
        this.dataLaporan = data.listLaporan.laporans;
        this.totalPages = data.listLaporan.totalPage;
        this.totalData = data.listLaporan.total;
      },
      error(e) {
        this.catchError(e);
      },
      watchLoading(isLoading) {
        this.loading = isLoading;
      }
    },
    listProduk: {
      query: () => Q_LIST_PRODUK,
      variables() {
        return {
          page: 1,
          limit: 99999,
          keyword: this.searchProduk
        };
      },
      result({ data }) {
        this.dataProduk = data.listProduk.produks;
      }
    },
    listRetailer: {
      query: () => Q_LIST_RETAILER,
      variables() {
        return {
          keyword: "",
          type: this.retailerTypeOptions[0],
          user_id: [],
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          province_id: this.getProvinceID(),
          kabupaten_id: this.getKabupatenID()
        };
      },
      result({ data }) {
        this.dataR1 = data.listRetailer.retailers;
      }
    },
    listRetailer1: {
      query: () => Q_LIST_RETAILER,
      variables() {
        return {
          keyword: "",
          type: this.retailerTypeOptions[1],
          user_id: [],
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          province_id: this.getProvinceID(),
          kabupaten_id: this.getKabupatenID()
        };
      },
      update: data => data.listRetailer,
      result({ data }) {
        this.dataR2 = data.listRetailer.retailers;
      }
    },
    listDistributor: {
      query: () => Q_LIST_DISTRIBUTOR,
      variables() {
        return {
          keyword: "",
          user_id: [JwtService.getID()],
          country_id: this.getCountryID(),
          region_id: this.getRegionID(),
          province_id: [],
          kabupaten_id: []
        };
      },
      result({ data }) {
        data.listDistributor.distributors.map(a => {
          this.dataDistributor.push({
            id: a.id,
            email: a.email,
            name: a.name,
            owner: a.owner ? a.owner : "-"
          });
        });
      }
    }
  },
  computed: {},
  methods: {
    handleInvoiceInput(index) {
      if (this.forms[index].invoice.size >= 2097152) {
        alert(`${this.$t("feedback.exceeds")} (2Mb)`);
        this.forms[index].invoice = null;
      }
    },
    addReportForm() {
      this.forms.push({});
    },
    removeReport(index) {
      this.forms.splice(index, 1);
    },
    getDay(value) {
      return moment(value).format("DD");
    },
    formatDate(value) {
      return moment(value).format("YYYY-MM-DD");
    },
    setFilterSelectedWeek() {
      this.filterSelected2 = this.lastDayWeek;
      this.customFilter = false;
      this.setPage();
    },
    setFilterSelectedMonth() {
      this.filterSelected2 = this.lastDayMonth;
      this.customFilter = false;
      this.setPage();
    },
    resetFilter() {
      this.filterSelected1 = "";
      this.filterSelected2 = "";
      this.filterDateType = null;
      this.customFilter = false;
      this.filterProduk = [];
      this.filterR2 = [];
      this.filterR1 = [];
      this.filterDist = [];
    },
    resetFilter1() {
      this.filterSelected2 = "";
      this.customFilter = false;
      this.setPage();
    },

    setPage() {
      this.currPage = 1;
    },
    addPage() {
      if (this.currPage < this.totalPages) {
        this.currPage++;
      }
    },
    redcPage() {
      if (this.currPage > 1) {
        this.currPage--;
      }
    },
    selectAll() {
      this.lapIDs = [];
      if (!this.allSelected) {
        for (let i in this.dataLaporan) {
          this.lapIDs.push(this.dataLaporan[i].id);
        }
      }
    },
    select() {
      this.allSelected = false;
    },
    setLiquidationValue(index) {
      if (
        typeof this.forms[index].price === "number" &&
        typeof this.forms[index].quantity === "string"
      ) {
        this.forms[index].value =
          this.forms[index].price * this.forms[index].quantity;
      }
    },
    onAdd() {
      this.modalTitle = this.$t("liq_reporting.add");
      this.isEdit = false;
      this.isDetail = false;
      this.isDisabled = false;
      this.show_filter = false;
      this.resetForm();
      this.$bvModal.show("modal-laporan");
    },
    onDetail(data) {
      this.modalTitle = this.$t("liq_reporting.detail");
      this.isEdit = false;
      this.isDetail = true;

      this.formDetail = {
        ...data,
        day_report: this.dateFr(data.day_report, "DD/MM/YYYY")
      };

      this.$bvModal.show("modal-laporan");
    },
    closeModal() {
      this.$bvModal.hide("modal-laporan");
    },
    resetForm() {
      this.forms = [{}];
      this.$v.$reset();
    },
    async onSubmit() {
      this.$v.$touch();

      if (!this.$v.forms.$error) {
        this.loading = true;
        const uploadInvoicePromises = this.forms.map(async a => {
          const formData = new FormData();
          formData.append("tipe", "sales-report");
          formData.append("file", a.invoice);
          const res = await axios.post(
            `${process.env.VUE_APP_URL_GRAPHQL}/image/upload`,
            formData,
            {
              headers: {
                Authorization: "Bearer " + JwtService.getToken(),
                "Content-Type": "multipart/form-data"
              }
            }
          );
          a.invoice = res.data.data;
          a.quantity = parseFloat(a.quantity);
          a.value = a.value.toString();
          delete a.rs;
        });

        await Promise.all(uploadInvoicePromises);

        return new Promise((resolve, reject) => {
          const addReport = this.$apollo.mutate({
            mutation: createMultipleReport,
            variables: {
              from: JwtService.getID(),
              day_report: this.formatDate(new Date()),
              month: this.month[new Date().getMonth()],
              year: new Date().getFullYear().toString(),
              item: this.forms
            }
          });
          if (resolve) {
            resolve(addReport);
          } else {
            reject(addReport);
          }
        })
          .then(response => {
            this.$apollo.queries.listLaporan.refetch();
            if (response) {
              this.toastAlert(this.$t("alert.add"));
              this.loading = false;
              this.closeModal();
            }
          })
          .catch(err => {
            this.$swal.fire({
              icon: "error",
              title: "Error!",
              text: err.message,
              width: 400,
              confirmButtonText: "OK"
            });
            this.loading = false;
          });
      }
    },
    async handleExport() {
      this.isLoading = true;
      await axios
        .post(
          process.env.VUE_APP_URL_GRAPHQL + "/laporan/excel",
          {
            dateType: this.handleCustomDateExport(this.filterDateType),
            start_date: this.filterSelected1,
            end_date: this.filterSelected2,
            user: [JwtService.getID()],
            region: [],
            product: this.filterProduk
          },
          {
            responseType: "arraybuffer",
            headers: {
              Authorization: "Bearer " + JwtService.getToken(),
              "Content-Type": "application/json"
            }
          }
        )
        .then(response => {
          const type = response.headers["content-type"];
          const blob = new Blob([response.data], {
            type: type,
            encoding: "UTF-8"
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `likuidasi-${this.dateFr(
            new Date(),
            "DDMMYYYYHHMMss"
          )}.xlsx`;
          link.click();
        })
        .catch(e => {
          alert(e);
        });
      this.isLoading = false;
    }
  },
  mounted() {
    const dateNow = new Date(new Date().setHours(0, 0, 0, 0));
    this.firstDayMonth = new Date(dateNow.getFullYear(), dateNow.getMonth(), 1);
    this.lastDayMonth = new Date(
      dateNow.getFullYear(),
      dateNow.getMonth() + 1,
      0
    );
  }
};
</script>

<style></style>
